import React, { FC, useState, useEffect } from "react";
import "./TechCategoryList.css";
import RightSideList from "../RightSideList/RightSideList";
import { TechArticles, techCategory } from "../../interfaces";
import axios from "axios";
import { API_URL } from "../../constants/urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode, // Programming & Development
  faPalette, // Web Design & UX/UI
  faLightbulb, // Startups & Innovation
  faMobileAlt, // Gadgets & Consumer Tech
  faCloud, // Cloud Computing & Enterprise Tech
  faShieldAlt, // Cybersecurity
  faRobot, // AI & Machine Learning
  faGamepad, // Gaming Technology
  faMobile, // Mobile Development
  faNewspaper, // Tech News & Trends
  faDatabase, // Data Science & Analytics
  IconDefinition, // Data Science & Analytics
} from "@fortawesome/free-solid-svg-icons";
import { faReact, faLinux } from "@fortawesome/free-brands-svg-icons"; // Correct import for Linux and React
import { useLocation } from "react-router-dom";
import ArticleLink from "../ArticleLink/ArticleLink";

interface TechCategoryListProps {}

const TechCategoryList: FC<TechCategoryListProps> = () => {
  const url = API_URL;
  const [categories, setCategories] = useState<techCategory[]>();
  const [popularContents, setPopularContents] = useState<TechArticles>();
  const [latestContents, setLatestContents] = useState<TechArticles>();
  const location = useLocation();

  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/articles?views=1`)
      .then((res) => {
        //setCountries(res?.data);
        setPopularContents(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/nuhman/tech/articles?created=1`)
      .then((res) => {
        //setCountries(res?.data);
        setLatestContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, [location]);

  // Call API once when component mounts
  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/categories`)
      .then((res) => {
        setCategories(res?.data);
      })
      .catch((err) => console.log(err));
  }, [url]);

  // Map the icon names to the actual Font Awesome icons
  const iconMapping: Record<string, IconDefinition | undefined> = {
    code: faCode,
    palette: faPalette,
    lightbulb: faLightbulb,
    mobileAlt: faMobileAlt,
    cloud: faCloud,
    shieldAlt: faShieldAlt,
    robot: faRobot,
    gamepad: faGamepad,
    mobile: faMobile,
    newspaper: faNewspaper,
    database: faDatabase,
    react: faReact,
    linux: faLinux,
  };

  // Function to get the icon from the mapping
  const getFontAwesomeIcon = (
    iconName: string | undefined
  ): IconDefinition | null => {
    return iconName ? iconMapping[iconName] || null : null;
  };

  function geTMap(arg0: string | undefined) {
    return arg0?.split(",").map((item) => item.trim());
  }

  return (
    <div className="container-fluid py-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="row mb-3">
              <div className="col-12">
                <div className="bg-light pdd">
                  {categories?.map((ctry: techCategory, index) => {
                    const icon = getFontAwesomeIcon(ctry.icon); // Fetch icon from the mapping

                    return (
                      <div className="col-lg-6 intdisp" key={index}>
                        <p>
                          <a
                            href={`/#/categories?catid=${ctry.id}&qqry=true&aqry=true`}
                          >
                            {icon && (
                              <FontAwesomeIcon
                                icon={icon}
                                style={{ marginRight: "8px" }} // Add spacing between icon and text
                              />
                            )}
                            {ctry.name}
                          </a>
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-12">&nbsp;</div>

              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                  <h3 className="m-0">Popular</h3>
                  <a
                    className="text-secondary font-weight-medium text-decoration-none"
                    href="/#/popular"
                  >
                    View All
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex mb-3">
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a
                        href={
                          "/#/categories?aqry=true&qqry=true&catid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[3]?.techCategory?.id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[3]?.techCategory?.name}
                      </a>
                      <span className="px-1">/</span>
                      <span>
                        {popularContents?.techArticles &&
                          new Date(
                            popularContents?.techArticles[3]?.createdDateTime ||
                              ""
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                      </span>
                    </div>
                    {popularContents?.techArticles && (
                      <ArticleLink
                        className="h6 m-0"
                        title={popularContents?.techArticles[3]?.title || ""}
                        id={popularContents?.techArticles[3]?.id || 0}
                      />
                    )}
                    <p className="tspl">
                      {geTMap(
                        popularContents?.techArticles &&
                          popularContents?.techArticles[3].tags
                      )
                        ?.slice(0, 5)
                        ?.map((ctry: string, index) => (
                          <a href={"/#/tags?name=" + ctry} key={index}>
                            <span className="tsps">{ctry}</span>
                          </a>
                        ))}
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a
                        href={
                          "/#/categories?aqry=true&qqry=true&catid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[1]?.techCategory?.id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[1]?.techCategory?.name}
                      </a>
                      <span className="px-1">/</span>
                      <span>
                        {popularContents?.techArticles &&
                          new Date(
                            popularContents?.techArticles[1]?.createdDateTime ||
                              ""
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                      </span>
                    </div>
                    {popularContents?.techArticles && (
                      <ArticleLink
                        className="h6 m-0"
                        title={popularContents?.techArticles[1]?.title || ""}
                        id={popularContents?.techArticles[1]?.id || 0}
                      />
                    )}

                    <p className="tspl">
                      {geTMap(
                        popularContents?.techArticles &&
                          popularContents?.techArticles[1].tags
                      )
                        ?.slice(0, 5)
                        ?.map((ctry: string, index) => (
                          <a href={"/#/tags?name=" + ctry} key={index}>
                            <span className="tsps">{ctry}</span>
                          </a>
                        ))}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex mb-3">
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a
                        href={
                          "/#/categories?aqry=true&qqry=true&catid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[2]?.techCategory?.id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[2]?.techCategory?.name}
                      </a>
                      <span className="px-1">/</span>
                      <span>
                        {popularContents?.techArticles &&
                          new Date(
                            popularContents?.techArticles[2]?.createdDateTime ||
                              ""
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                      </span>
                    </div>
                    {popularContents?.techArticles && (
                      <ArticleLink
                        className="h6 m-0"
                        title={popularContents?.techArticles[2]?.title || ""}
                        id={popularContents?.techArticles[2]?.id || 0}
                      />
                    )}
                    <p className="tspl">
                      {geTMap(
                        popularContents?.techArticles &&
                          popularContents?.techArticles[2].tags
                      )
                        ?.slice(0, 5)
                        ?.map((ctry: string, index) => (
                          <a href={"/#/tags?name=" + ctry} key={index}>
                            <span className="tsps">{ctry}</span>
                          </a>
                        ))}
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a
                        href={
                          "/#/categories?aqry=true&qqry=true&catid=" +
                          (popularContents?.techArticles &&
                            popularContents?.techArticles[0]?.techCategory?.id)
                        }
                      >
                        {popularContents?.techArticles &&
                          popularContents?.techArticles[0]?.techCategory?.name}
                      </a>
                      <span className="px-1">/</span>
                      <span>
                        {popularContents?.techArticles &&
                          new Date(
                            popularContents?.techArticles[0]?.createdDateTime ||
                              ""
                          ).toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                      </span>
                    </div>
                    {popularContents?.techArticles && (
                      <ArticleLink
                        className="h6 m-0"
                        title={popularContents?.techArticles[0]?.title || ""}
                        id={popularContents?.techArticles[0]?.id || 0}
                      />
                    )}
                    <p className="tspl">
                      {geTMap(
                        popularContents?.techArticles &&
                          popularContents?.techArticles[0].tags
                      )
                        ?.slice(0, 5)
                        ?.map((ctry: string, index) => (
                          <a href={"/#/tags?name=" + ctry} key={index}>
                            <span className="tsps">{ctry}</span>
                          </a>
                        ))}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12">&nbsp;</div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">Latest</h3>
                    <a
                      className="text-secondary font-weight-medium text-decoration-none"
                      href="/#/latest"
                    >
                      View All
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[0]?.techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[0].techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[0]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      {popularContents?.techArticles && (
                        <ArticleLink
                          className="h6 m-0"
                          title={popularContents?.techArticles[0]?.title || ""}
                          id={popularContents?.techArticles[0]?.id || 0}
                        />
                      )}
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[0].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[1].techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[1].techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[1]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>

                      {latestContents?.techArticles && (
                        <ArticleLink
                          className="h6 m-0"
                          title={
                            latestContents?.techArticles[1]?.title?.substring(
                              0,
                              66
                            ) + "...." || ""
                          }
                          id={latestContents?.techArticles[1]?.id || 0}
                        />
                      )}
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[1].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[2]?.techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[2]?.techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[2]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      {popularContents?.techArticles && (
                        <ArticleLink
                          className="h6 m-0"
                          title={popularContents?.techArticles[2]?.title || ""}
                          id={popularContents?.techArticles[2]?.id || 0}
                        />
                      )}
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[2].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?aqry=true&qqry=true&catid=" +
                            (latestContents?.techArticles &&
                              latestContents?.techArticles[3]?.techCategory?.id)
                          }
                        >
                          {latestContents?.techArticles &&
                            latestContents?.techArticles[3]?.techCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.techArticles &&
                            new Date(
                              latestContents?.techArticles[3]
                                ?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      {popularContents?.techArticles && (
                        <ArticleLink
                          className="h6 m-0"
                          title={popularContents?.techArticles[3]?.title || ""}
                          id={popularContents?.techArticles[3]?.id || 0}
                        />
                      )}
                      <p className="tspl">
                        {geTMap(
                          latestContents?.techArticles &&
                            latestContents?.techArticles[3].tags
                        )
                          ?.slice(0, 5)
                          ?.map((ctry: string, index) => (
                            <a href={"/#/tags?name=" + ctry} key={index}>
                              <span className="tsps">{ctry}</span>
                            </a>
                          ))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RightSideList />
        </div>
      </div>
    </div>
  );
};

export default TechCategoryList;
