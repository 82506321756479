import React, { FC, useEffect, useState } from "react";
import "./Login.css";
import { AuthRequest, DeviceInfo, educationCategory } from "../../interfaces";
import axios from "axios";
import CategorySlider1 from "../CategorySlider1/CategorySlider1";
import CategorySlider2 from "../CategorySlider2/CategorySlider2";
import FeaturedSlider from "../FeaturedSlider/FeaturedSlider";
import Sidebar from "../Sidebar/Sidebar";
import TopSlider from "../TopSlider/TopSlider";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { removeToken, storeToken } from "../../util/userDataSlice";
import { authUser } from "../../auth/userAuth";
import { saveToken } from "../../store/sessionManager";
import { API_URL } from "../../constants/urls";

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const [categories, setCategories] = useState<educationCategory[]>();
  const [error, setError] = useState<String>("");
  const token = useSelector((state: RootState) => state.userData.token);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rMe, setrMe] = useState(false);
  const navigate = useNavigate();
  const url = API_URL;

  useEffect(() => {
    const userStore = localStorage.getItem("username");
    const passStore = localStorage.getItem("password");
    if (userStore) {
      setEmail(userStore);
    }
    if (passStore) {
      setPassword(passStore);
    }
  }, []);

  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // const loginHealthCheck = await authHealthCheck()
    // console.log('data', loginHealthCheck.data)

    const deviceInfo: DeviceInfo = {
      deviceId: Math.random().toString(),
      deviceType: 0,
      notificationToken: Math.random().toString(),
    };

    const userData: AuthRequest = {
      email: email,
      password: password,
      deviceInfo: deviceInfo,
    };

    axios
      .post(url + `v2/api/auth/login`, userData)
      .then((res) => {
        // setCountries(res?.data);
        if (res.status === 200) {
          if (rMe) {
            localStorage.setItem("username", email);
            localStorage.setItem("password", password);
          }

          dispatch(storeToken(res.data.accessToken));
          //showSuccessToast("Login successful!");
          saveToken(res.data.accessToken);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleLogoutSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(removeToken());
  };

  return (
    <>
      <>
        <div className="container-fluid py-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mmargin">
                <div className="bg-light rresults">
                  <div className="mb-3 rresult">
                    <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      Login
                    </h2>

                    <div className="position-relative overflow-hidden mb-3 cat">
                      <form onSubmit={handleLoginSubmit}>
                        <div className="div2">
                          <div className="control-group div1">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="username"
                              placeholder="Username"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              required
                              data-validation-required-message="Please enter your username"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                        <div className="div2">
                          <div className="control-group div1">
                            <input
                              type="password"
                              className="form-control p-4"
                              id="password"
                              placeholder="Password"
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              required
                              data-validation-required-message="Please enter your password"
                            />
                            <p className="help-block text-danger">{error}</p>
                          </div>
                        </div>
                        <div className="div2">
                          <p>
                            <input
                              type="checkbox"
                              checked={rMe}
                              onChange={() => setrMe(!rMe)}
                            />
                            &nbsp; Remember Me
                          </p>
                        </div>
                        <div className="div2">
                          <div>
                            <button
                              className="btn btn-primary font-weight-semi-bold px-4"
                              style={{ height: 50 }}
                              type="submit"
                              id="sendMessageButton"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="div2">
                        <div>
                          <p></p>
                          <p>
                            <a href="/#/forgot-password">Forgot Password</a> or{" "}
                            <a href="/#/register">Register</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="bg-light rresultser">
                  <div className="mb-3 rresult">
                    <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      <h3 className="m-0">Articles</h3>
                      <a
                        className="text-secondary font-weight-medium text-decoration-none"
                        href=""
                      >
                        View All
                      </a>
                    </div>
                    <div>
                      {categories
                        ?.slice(0, 5)
                        .map((ctry: educationCategory, index) => (
                          <div
                            className="position-relative overflow-hidden mb-3 cat"
                            key={index}
                          >
                            <h5>
                              <a href={"/#/categories?catid=" + ctry.id}>
                                {" "}
                                {ctry.name}
                              </a>{" "}
                            </h5>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Login;
