import React, { FC, useEffect, useState } from "react";
import "./UUIDGenerator.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import InvoiceGenerator from "../InvoiceGenerator/InvoiceGenerator";
import { API_URL } from "../../constants/urls";

interface UUIDGeneratorProps {}

const UUIDGenerator: FC<UUIDGeneratorProps> = () => {
  const [uuid, setUuid] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  function generateUUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function submitContact(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setLoading(true);
    setUuid(generateUUID());
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true);
    setUuid(generateUUID());
    setLoading(false);
  }, []);

  useEffect(() => {
    const url = API_URL;
    axios
      .post(url + `apis/nuhman/tech/page-view?pageId=` + UUIDGenerator.name)
      .then((res) => {
        //setCountries(res?.data);
        //setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - UUID generator Tool"}</title>
        <meta
          name="description"
          content={"Generate a random UUID - UUID generator"}
        />
        <meta
          property="og:title"
          content={"Nuhman.com - UUID generation tool"}
        />
        <meta
          property="og:description"
          content={"Generate a new UUID with this tool"}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="overlay position-relative bg-light rresult">
              <div className="mb-3 rresult col-lg-12">
                <h3 className=" mg">{"UUID generation tool"}</h3>
                <p>Generate a random UUID using this tool</p>
                <form
                  name="sentMessage"
                  id="contactForm"
                  onSubmit={submitContact}
                >
                  <div>
                    <button
                      className="btn btn-primary font-weight-semi-bold px-4"
                      style={{ height: 50 }}
                      type="submit"
                      id="sendMessageButton"
                    >
                      Generate New
                    </button>
                  </div>
                </form>
                <div>
                  {uuid && (
                    <>
                      {" "}
                      <br />
                      <p>{uuid}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default UUIDGenerator;
