import React, { FC, useEffect, useState } from "react";
import "./Popular.css";
import { API_URL } from "../../constants/urls";
import { TechArticle, TechArticles } from "../../interfaces";
import axios from "axios";
import ArticleLink from "../ArticleLink/ArticleLink";

interface PopularProps {}

const Popular: FC<PopularProps> = () => {
  const [popularContents, setPopularContents] = useState<TechArticles>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [size, setSize] = useState<number>(10); // Default page size
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const url = API_URL;
  // Fetch articles based on the current page
  const fetchArticles = (page: number) => {
    setLoading(true); // Start loading
    axios
      .get(`${url}/apis/nuhman/tech/articles?views=1&page=${page}&size=${size}`)
      .then((res) => {
        setPopularContents(res.data);
        setCurrentPage(res.data.currentPage);
        setTotalPages(res.data.totalPages);
        setLoading(false); // Stop loading
      })
      .catch((err) => {
        console.log(err);
        setLoading(false); // Stop loading in case of error
      });
  };

  useEffect(() => {
    fetchArticles(0); // Initial fetch for page 0
  }, []);

  // Function to handle page changes
  const handlePageChange = (newPage: number) => {
    fetchArticles(newPage);
  };
  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">Popular Articles</h3>
                  </div>
                </div>
                <div className="col-lg-12">
                  {/* Show loading spinner while fetching data */}
                  {loading && (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}

                  {/* Show articles when not loading */}
                  {!loading &&
                    popularContents?.techArticles?.map(
                      (article: TechArticle, index) => (
                        <div className="col-lg-6 d-flex mb-3 fl6" key={index}>
                          <div className="w-100 d-flex flex-column justify-content-center bg-light px-3">
                            <div className="mb-1 headerthirteen">
                              <a
                                href={`/categories?catid=${article.techCategory?.id}`}
                              >
                                {article.techCategory?.name}
                              </a>
                              <span className="px-1">/</span>
                              <span>
                                {new Date(
                                  article.createdDateTime || ""
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </span>
                            </div>
                            {article && (
                              <ArticleLink
                                className="h6 m-0"
                                title={
                                  article?.title?.substring(0, 66) + "...." ||
                                  ""
                                }
                                id={article?.id || 0}
                              />
                            )}
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>

              {/* Pagination controls */}
              <div className="pagination-controls d-flex justify-content-center align-items-center mt-3">
                <button
                  className="btn btn-outline-primary me-2"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 0 || loading}
                >
                  Previous
                </button>
                <span className="mx-3">{`Page ${
                  currentPage + 1
                } of ${totalPages}`}</span>
                <button
                  className="btn btn-outline-primary ms-2"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage + 1 >= totalPages || loading}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popular;
