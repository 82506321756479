import React, { FC, useEffect, useState } from "react";
import "./Navbar.css";
import { techCategory } from "../../interfaces";
import axios from "axios";
import { API_URL } from "../../constants/urls";

interface NavbarProps {}

const Navbar: FC<NavbarProps> = () => {
  const [techcategories, setTechcategories] = useState<techCategory[]>();
  const url = API_URL;
  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/categories`)
      .then((res) => {
        setTechcategories(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="container-fluid p-0 mb-3">
        <nav className="navbar navbar-expand-lg bg-light navbar-light py-2 py-lg-0 px-lg-5">
          <a href="" className="navbar-brand d-block d-lg-none">
            <h1 className="m-0 display-5 text-uppercase">
              <span className="text-primary">NUHMAN</span>.com
            </h1>
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-between px-0 px-lg-3"
            id="navbarCollapse"
          >
            <div className="navbar-nav mr-auto py-0">
              <a href="/" className="nav-item nav-link active">
                Home
              </a>
              <a href="/#/categories" className="nav-item nav-link">
                Categories
              </a>
              {/* <div className="nav-item dropdown">
                <a
                  href="/"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Q & A
                </a>
                <div className="dropdown-menu rounded-0 m-0">
                  {techcategories && (
                    <>
                      {techcategories?.map((ctry: techCategory, index) => (
                        <a
                          href={"/#/categories?catid=" + ctry.id + "&qqry=true"}
                          className="dropdown-item"
                        >
                          {ctry.name}
                        </a>
                      ))}
                    </>
                  )}
                  ;
                </div>
              </div> */}
              <div className="nav-item dropdown">
                <a
                  href="/"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Article
                </a>
                <div className="dropdown-menu rounded-0 m-0">
                  {techcategories && (
                    <>
                      {techcategories?.map((ctry: techCategory, index) => (
                        <a
                          href={"/#/categories?catid=" + ctry.id + "&aqry=true"}
                          className="dropdown-item"
                        >
                          {ctry.name}
                        </a>
                      ))}
                    </>
                  )}
                  ;
                </div>
              </div>
              <div className="nav-item dropdown">
                <a
                  href="/"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  Tools & Utilities
                </a>
                <div className="dropdown-menu rounded-0 m-0">
                  <a href="/#/keyword-extraction" className="dropdown-item">
                    Keyword Tool
                  </a>
                  <a href="/#/uuid-generator" className="dropdown-item">
                    UUID Generator Tool
                  </a>
                  {/* <a href="/#/survey-builder-tool" className="dropdown-item">
                    Survey Builder Tool
                  </a> */}
                  <a href="/#/jwt-token-generator" className="dropdown-item">
                    Jwt Token Generator
                  </a>
                  <a href="/#/json-formatter" className="dropdown-item">
                    Json Formatter
                  </a>
                  <a href="/#/regex-generator" className="dropdown-item">
                    Regex Generator
                  </a>
                  <a href="/#/compare-text" className="dropdown-item">
                    Compare Text
                  </a>
                  <a href="/#/qr-code-generator" className="dropdown-item">
                    QR Code Generator
                  </a>
                  <a href="/#/invoice-generator" className="dropdown-item">
                    Invoice Generator
                  </a>
                  <a
                    href="/#/business-name-generator"
                    className="dropdown-item"
                  >
                    Business Name Generator
                  </a>
                  <a href="/#/integer-roman" className="dropdown-item">
                    Integer to Roman Generator
                  </a>
                  <a href="/#/time-zone-converter" className="dropdown-item">
                    Time Zone Converter
                  </a>
                </div>
              </div>
              <a href="/#/contact" className="nav-item nav-link">
                Contact
              </a>
            </div>
            <div className="input-group ml-auto" id="search">
              <input
                type="text"
                className="form-control"
                placeholder="Keyword"
              />
              <div className="input-group-append">
                <button className="input-group-text text-secondary">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
