import axios from "axios";
import { api } from "./api";
import { useNavigate } from "react-router-dom";
import { AUTH_API_URL } from "../constants/urls";

//const authApiUrl = `https://${process.env.REACT_APP_ENV}-auth.${process.env.REACT_APP_URL_API}`;
//const userApiUrl = `https://${process.env.REACT_APP_ENV}-users.${process.env.REACT_APP_URL_API}`;

const authApiUrl = AUTH_API_URL;
const userApiUrl = `https://${process.env.REACT_APP_ENV}-users.${process.env.REACT_APP_URL_API}`;

export const apiUser = async (params: any = {}) => {
  try {
    //return await api.get(`${userApiUrl}/users`, params);
    return await api.post(`http://127.0.0.1:5555/users`, params);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      return e.response?.data;
    }
    return e;
  }
};

export const apiAuth = async (params: any = {}) => {
  axios
    .post(`${authApiUrl}/api/auth/login`, params)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => console.error(error));
};

export const apiGet = async (url: string, params: any = {}) => {
  try {
    //return await api.post(`${authApiUrl}/auth/login`, params);
    return await api.get(`${authApiUrl}${url}`, params);
  } catch (e: any) {
    debugger;
    if (axios.isAxiosError(e)) {
      return e.response;
    }
    return e;
  }
};

export const apiPost = async (url: string, params: any = {}) => {
  try {
    //return await api.post(`${authApiUrl}/auth/login`, params);
    return await api.post(`${authApiUrl}${url}`, params);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      return e.response;
    }
    return e;
  }
};

export const apiPostWithFile = async (url: string, params: any = {}) => {
  try {
    //return await api.post(`${authApiUrl}/auth/login`, params);
    return await api.postWithFile(`${authApiUrl}${url}`, params);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      return e.response;
    }
    return e;
  }
};

export const apiDelete = async (url: string, params: any = {}) => {
  try {
    //return await api.post(`${authApiUrl}/auth/login`, params);
    return await api.delete(`${url}`, params);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      return e.response?.data;
    }
    return e;
  }
};
