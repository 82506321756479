import React, { FC, useEffect, useState } from "react";
import "./RegexGenerator.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API_URL } from "../../constants/urls";

interface MiddleSection {
  type: "string" | "number" | "special";
  length: number;
}

const RegexGenerator: React.FC = () => {
  // State for enabling or disabling start, middle, and end sections
  const [includeStart, setIncludeStart] = useState<boolean>(true);
  const [includeMiddle, setIncludeMiddle] = useState<boolean>(true);
  const [includeEnd, setIncludeEnd] = useState<boolean>(true);

  const [startType, setStartType] = useState<"string" | "number" | "special">(
    "string"
  );
  const [startLength, setStartLength] = useState<number>(1);

  const [middles, setMiddles] = useState<MiddleSection[]>([
    { type: "string", length: 1 },
  ]);

  const [endType, setEndType] = useState<"string" | "number" | "special">(
    "string"
  );
  const [endLength, setEndLength] = useState<number>(1);

  const [testString, setTestString] = useState<string>(""); // Test input string

  const handleAddMiddle = () => {
    setMiddles([...middles, { type: "string", length: 1 }]);
  };

  const handleMiddleChange = (
    index: number,
    field: keyof MiddleSection,
    value: string | number
  ) => {
    const updatedMiddles = middles.map((middle, i) =>
      i === index ? { ...middle, [field]: value } : middle
    );
    setMiddles(updatedMiddles);
  };

  const getPattern = (
    type: "string" | "number" | "special",
    length: number
  ): string => {
    switch (type) {
      case "number":
        return `\\d{${length}}`;
      case "special":
        return `[!@#$%^&*()_+]{${length}}`;
      case "string":
      default:
        return `[a-zA-Z]{${length}}`;
    }
  };

  const generateRegex = (): string => {
    let pattern = "";

    if (includeStart) {
      const startPattern = getPattern(startType, startLength);
      pattern += startPattern;
    }

    if (includeMiddle) {
      const middlePattern = middles
        .map((middle) => getPattern(middle.type, middle.length))
        .join("");
      pattern += middlePattern;
    }

    if (includeEnd) {
      const endPattern = getPattern(endType, endLength);
      pattern += endPattern;
    }

    return `${pattern}`; // Removed ^ and $ to allow partial matching
  };

  const regexPattern = generateRegex();
  const regex = new RegExp(regexPattern, "g"); // Global flag to find multiple matches

  const highlightMatches = () => {
    let parts = [];
    let remainingString = testString;
    let match;

    // Iterating through the testString to find all matches
    while ((match = regex.exec(remainingString)) !== null) {
      const beforeMatch = remainingString.substring(0, match.index);
      const matchedString = match[0];

      // Push non-matched part and matched part separately
      parts.push(<span key={parts.length}>{beforeMatch}</span>);
      parts.push(
        <span key={parts.length} className="highlight">
          {matchedString}
        </span>
      );

      // Update the remaining string to process
      remainingString = remainingString.substring(
        match.index + matchedString.length
      );
    }

    // Push any remaining part after the last match
    parts.push(<span key={parts.length}>{remainingString}</span>);

    return <>{parts}</>;
  };

  useEffect(() => {
    const url = API_URL;
    axios
      .post(url + `apis/nuhman/tech/page-view?pageId=` + RegexGenerator.name)
      .then((res) => {
        //setCountries(res?.data);
        //setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {" "}
      <Helmet>
        <title>{"Nuhman.com - Regex Generator"}</title>
        <meta
          name="description"
          content={"Generate a regular expression to match a substring"}
        />
        <meta property="og:title" content={"Nuhman.com - Regex Generator"} />
        <meta
          property="og:description"
          content={"Generate a regular expression to match a substring"}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="overlay position-relative bg-light rresult">
              <div className="mb-3 rresult col-lg-12">
                <h3 className=" mg">{"Regex Generator"}</h3>
                <p>
                  Regular expression generator, Generate and test with test
                  string
                </p>

                <div>
                  {/* Start Section */}
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={includeStart}
                        onChange={() => setIncludeStart(!includeStart)}
                      />
                      Include Start
                    </label>
                    {includeStart && (
                      <div>
                        <h3>Start</h3>
                        <select
                          value={startType}
                          onChange={(e) =>
                            setStartType(
                              e.target.value as "string" | "number" | "special"
                            )
                          }
                        >
                          <option value="string">String</option>
                          <option value="number">Number</option>
                          <option value="special">Special Characters</option>
                        </select>
                        <input
                          type="number"
                          value={startLength}
                          onChange={(e) =>
                            setStartLength(parseInt(e.target.value))
                          }
                          min={1}
                        />
                      </div>
                    )}
                  </div>

                  {/* Middle Section */}
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={includeMiddle}
                        onChange={() => setIncludeMiddle(!includeMiddle)}
                      />
                      Include Middle
                    </label>
                    {includeMiddle && (
                      <div>
                        <h3>Middle</h3>
                        {middles.map((middle, index) => (
                          <div key={index}>
                            <select
                              value={middle.type}
                              onChange={(e) =>
                                handleMiddleChange(
                                  index,
                                  "type",
                                  e.target.value as
                                    | "string"
                                    | "number"
                                    | "special"
                                )
                              }
                            >
                              <option value="string">String</option>
                              <option value="number">Number</option>
                              <option value="special">
                                Special Characters
                              </option>
                            </select>
                            <input
                              type="number"
                              value={middle.length}
                              onChange={(e) =>
                                handleMiddleChange(
                                  index,
                                  "length",
                                  parseInt(e.target.value)
                                )
                              }
                              min={1}
                            />
                          </div>
                        ))}
                        <button onClick={handleAddMiddle}>Add Middle</button>
                      </div>
                    )}
                  </div>

                  {/* End Section */}
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={includeEnd}
                        onChange={() => setIncludeEnd(!includeEnd)}
                      />
                      Include End
                    </label>
                    {includeEnd && (
                      <div>
                        <h3>End</h3>
                        <select
                          value={endType}
                          onChange={(e) =>
                            setEndType(
                              e.target.value as "string" | "number" | "special"
                            )
                          }
                        >
                          <option value="string">String</option>
                          <option value="number">Number</option>
                          <option value="special">Special Characters</option>
                        </select>
                        <input
                          type="number"
                          value={endLength}
                          onChange={(e) =>
                            setEndLength(parseInt(e.target.value))
                          }
                          min={1}
                        />
                      </div>
                    )}
                  </div>

                  {/* Display Generated Regex */}
                  <div>
                    <h3>Generated Regex:</h3>
                    <pre>{regexPattern}</pre>
                  </div>

                  {/* Test String Textarea */}
                  <div>
                    <h3>Test String</h3>
                    <textarea
                      value={testString}
                      onChange={(e) => setTestString(e.target.value)}
                      rows={4}
                      cols={50}
                    ></textarea>
                  </div>

                  {/* Display the Test String with Highlights */}
                  <div>
                    <h3>Highlighted Matches</h3>
                    <pre>{highlightMatches()}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default RegexGenerator;
