import React, { FC, useState } from "react";
import "./ArticleManager.css";
import ExpandableMenu from "../ExpandableMenu/ExpandableMenu";
import ManagerRightDiv from "../ManagerRightDiv/ManagerRightDiv";

interface ArticleManagerProps {}

const ArticleManager: FC<ArticleManagerProps> = () => {
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("home");
  return (
    <>
      <div className="container">
        <div className="row">
          <ExpandableMenu activeTabs={activeTab} expandedMenus={expandedMenu} />
          <ManagerRightDiv aid={5} />
        </div>
      </div>
    </>
  );
};
export default ArticleManager;
