import React, { FC, useEffect, useState } from "react";
import "./InvoiceGenerator.css";
import { Helmet } from "react-helmet";
import { FaPlus } from "react-icons/fa";
import printJS from "print-js";
import axios from "axios";
import { API_URL } from "../../constants/urls";

interface InvoiceGeneratorProps {}

interface LineItem {
  description: string;
  qty: string;
  rate: string;
  amount: string;
}

const InvoiceGenerator: FC<InvoiceGeneratorProps> = () => {
  const [header, setHeader] = useState<boolean>(false);
  const [headerValue, setHeaderValue] = useState("INVOICE");
  const [lineItems, setLineItems] = useState<LineItem[]>([
    { description: "", qty: "", rate: "", amount: "0.00" },
  ]);
  const [logo, setLogo] = useState<string | null>(null);

  const [gstLabel, setGstLabel] = useState("GST (10%)");
  const [gstPercentage, setGstPercentage] = useState(10); // Default GST percentage
  const [subTotalLabel, setSubTotalLabel] = useState("Sub Total");
  const [totalLabel, setTotalLabel] = useState("Total");
  const [isBorderless, setIsBorderless] = useState<boolean>(false);
  const [addNewItem, setAddNewItem] = useState<boolean>(true);
  const [notesLabel, setNotesLabel] = useState("Notes");
  const [notesValue, setNotesValue] = useState(
    "It was great doing business with you."
  );

  const [termsLabel, setTermsLabel] = useState("Terms & Conditions");
  const [termsValue, setTermsValue] = useState(
    "Please make the payment by the due date."
  );
  const url = API_URL;

  // Function to add a new line item
  const addLineItem = () => {
    setLineItems([
      ...lineItems,
      { description: "", qty: "", rate: "", amount: "0.00" },
    ]);
  };

  // Function to remove a line item by index
  const removeLineItem = (index: number) => {
    const updatedItems = lineItems.filter((_, i) => i !== index);
    setLineItems(updatedItems);
  };

  // Function to automatically calculate the amount based on qty and rate
  const calculateAmount = (qty: string, rate: string): string => {
    const quantity = parseFloat(qty) || 0;
    const rateValue = parseFloat(rate) || 0;
    return (quantity * rateValue).toFixed(2); // Returns amount as a string with 2 decimal places
  };

  // Function to update a line item's value
  const handleInputChange = (
    index: number,
    field: keyof LineItem,
    value: string
  ) => {
    const updatedItems = [...lineItems];
    updatedItems[index][field] = value;

    if (field === "qty" || field === "rate") {
      const updatedQty = field === "qty" ? value : updatedItems[index].qty;
      const updatedRate = field === "rate" ? value : updatedItems[index].rate;
      updatedItems[index].amount = calculateAmount(updatedQty, updatedRate);
    }

    setLineItems(updatedItems);
  };

  // Calculate Sub Total
  const calculateSubTotal = (): number => {
    return lineItems.reduce(
      (acc, item) => acc + parseFloat(item.amount || "0"),
      0
    );
  };

  // Calculate GST
  const calculateGst = (subTotal: number): number => {
    return (subTotal * gstPercentage) / 100;
  };

  // Calculate Total
  const calculateTotal = (subTotal: number, gst: number): number => {
    return subTotal + gst;
  };

  const subTotal = calculateSubTotal();
  const gst = calculateGst(subTotal);
  const total = calculateTotal(subTotal, gst);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setLogo(URL.createObjectURL(file)); // Create an object URL for the selected image
    }
  };

  const handlePrint = () => {
    setIsBorderless(!isBorderless);
    setAddNewItem(false);
    //setIsBorderless(!isBorderless);
  };

  useEffect(() => {
    const url = API_URL;
    axios
      .post(url + `apis/nuhman/tech/page-view?pageId=` + InvoiceGenerator.name)
      .then((res) => {
        //setCountries(res?.data);
        //setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!addNewItem) {
      printJS({
        printable: "test",
        type: "html",
        targetStyles: ["*"], // Prints with current styles
      });
      setAddNewItem(true);
    }
  }, [addNewItem]);

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - Invoice generator Tool"}</title>
        <meta
          name="description"
          content={"Generate an invoice by adding or editing this template"}
        />
        <meta
          property="og:title"
          content={"Nuhman.com - Invoice generator Tool"}
        />
        <meta
          property="og:description"
          content={"Generate an invoice by adding or editing this template"}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8  pep">
            {" "}
            <div className="overlay position-relative bg-light rresult peps">
              <h3 className=" mg">{"Invoice Generator"}</h3>
              <p>Generate an invoice by editing this page</p>
              <button
                onClick={(e) => {
                  e.preventDefault(); // Prevents default behavior
                  handlePrint(); // Your function call
                }}
              >
                Print
              </button>{" "}
              Or save/reuse with an Account <a href={""}>Register/Signin</a>
            </div>
          </div>
          <div className="col-lg-4"> </div>
        </div>

        <div className="row">
          <div className={"col-lg-8 "} id="test">
            <div className="overlay position-relative bg-light rresult">
              <div className="mb-3 rresult col-lg-12">
                <form name="sentMessage" id="contactForm">
                  <div className="form-row">
                    <div className="col-md-6">
                      <p>
                        {" "}
                        <div className="image logo">
                          {/* Hidden file input */}
                          <input
                            type="file"
                            accept="image/*"
                            id="logo-upload"
                            style={{ display: "none" }} // Hide the file input
                            onChange={handleImageUpload} // Handle image selection
                          />

                          {/* Button to trigger file input, hidden if logo is uploaded */}
                          {!logo && (
                            <button
                              type="button"
                              className="image__upload"
                              onClick={() =>
                                document.getElementById("logo-upload")?.click()
                              }
                            >
                              Your Logo
                            </button>
                          )}

                          {/* Display the uploaded image */}
                          {logo && (
                            <div className="image-container">
                              <img
                                src={logo}
                                alt="Uploaded Logo"
                                onClick={() =>
                                  document
                                    .getElementById("logo-upload")
                                    ?.click()
                                } // Make the image clickable to change it
                                className="uploaded-logo"
                              />
                              <div
                                className="hover-text"
                                onClick={() =>
                                  document
                                    .getElementById("logo-upload")
                                    ?.click()
                                }
                              >
                                Click to replace
                              </div>
                            </div>
                          )}
                          <br />
                        </div>
                        <p></p>
                        <input
                          type="text"
                          className={
                            addNewItem
                              ? "form-control p-1"
                              : "form-control p-1 no-border"
                          }
                          id="issuer"
                          name="issuer"
                          placeholder="Your Company"
                        />{" "}
                        <input
                          type="text"
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          id="issuer"
                          name="issuer"
                          placeholder="Your Name"
                        />
                        <input
                          type="text"
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          id="issuer"
                          name="issuer"
                          placeholder="Your Address"
                        />
                        <input
                          type="text"
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          id="issuer"
                          name="issuer"
                          placeholder="City, State Zip"
                        />
                        <input
                          type="text"
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          id="issuer"
                          name="issuer"
                          placeholder="Country"
                        />
                      </p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className={
                          addNewItem
                            ? "form-control p-4 txtrf"
                            : "form-control p-4 txtrf no-border-size"
                        }
                        id="issuer"
                        name="issuer"
                        placeholder="INVOICE"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <p></p>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className={
                          addNewItem
                            ? "form-control heighttext"
                            : "form-control heighttext no-borders"
                        }
                        id="issuer"
                        name="issuer"
                        placeholder="Bill:"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-row">
                        <div className="col-md-7">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            id="issuer"
                            name="issuer"
                            placeholder="Invoice#"
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            id="issuer"
                            name="issuer"
                            placeholder="INV-12"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className={
                          addNewItem
                            ? "form-control heighttext"
                            : "form-control heighttext no-borders"
                        }
                        id="issuer"
                        name="issuer"
                        placeholder="Your Client's Name"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-row">
                        <div className="col-md-7">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            id="issuer"
                            name="issuer"
                            placeholder="Invoice Date"
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            id="issuer"
                            name="issuer"
                            placeholder="Sep 30, 2024"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className={
                          addNewItem
                            ? "form-control heighttext"
                            : "form-control heighttext no-borders"
                        }
                        id="issuer"
                        name="issuer"
                        placeholder="Client's Address"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-row">
                        <div className="col-md-7">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            id="issuer"
                            name="issuer"
                            placeholder="Due Date"
                          />
                        </div>
                        <div className="col-md-5">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            id="issuer"
                            name="issuer"
                            placeholder="Sep 30, 2024"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className={
                          addNewItem
                            ? "form-control heighttext"
                            : "form-control heighttext no-borders"
                        }
                        id="issuer"
                        name="issuer"
                        placeholder="United State"
                      />
                    </div>
                    <div className="col-md-6"></div>
                  </div>

                  <div className="form-row">
                    <p></p>
                  </div>

                  <div className="form-row">
                    <table className="table thp">
                      <thead className="theads thp">
                        <tr className="thp">
                          <td className="thp">#</td>
                          <td className="thp">Qty</td>
                          <td className="thp">Rate</td>
                          <td className="thp">Amount</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {lineItems.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                className={
                                  addNewItem
                                    ? "form-control heighttext"
                                    : "form-control heighttext no-borders"
                                }
                                name="description"
                                placeholder="Enter Description- Eg - Brochure Design"
                                value={item.description}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={
                                  addNewItem
                                    ? "form-control heighttext"
                                    : "form-control heighttext no-borders"
                                }
                                name="qty"
                                placeholder="enter quantity"
                                value={item.qty}
                                pattern="[0-9]{0,5}"
                                onKeyDown={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "qty",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={
                                  addNewItem
                                    ? "form-control heighttext"
                                    : "form-control heighttext no-borders"
                                }
                                name="rate"
                                placeholder="enter item price"
                                value={item.rate}
                                onKeyDown={(event) => {
                                  if (!/[0-9.]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "rate",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className={
                                  addNewItem
                                    ? "form-control heighttext"
                                    : "form-control heighttext no-borders"
                                }
                                name="amount"
                                placeholder="450.00"
                                value={item.amount}
                                readOnly
                              />
                            </td>
                            <td>
                              {addNewItem && (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevents default behavior
                                    removeLineItem(index); // Your function call
                                  }}
                                  style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  ❌
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {addNewItem && (
                        <div style={{ marginTop: "10px", textAlign: "right" }}>
                          <button
                            onClick={(e) => {
                              e.preventDefault(); // Prevents default behavior
                              addLineItem(); // Your function call
                            }}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FaPlus style={{ marginRight: "5px" }} />{" "}
                            {/* Add the icon */}
                            Add Line Item
                          </button>
                        </div>
                      )}
                    </table>
                  </div>

                  <div className="form-row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">
                      <div className="form-row">
                        <div className="col-md-5">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            name="subtotal"
                            value={subTotalLabel}
                            onChange={(e) => setSubTotalLabel(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-3 txtr">
                          {subTotal.toFixed(2)}
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">
                      <div className="form-row">
                        <div className="col-md-5">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            name="GST"
                            value={gstLabel}
                            onChange={(e) => setGstLabel(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3">
                          <input
                            type="number"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-borders"
                            }
                            name="gstPercentage"
                            value={gstPercentage}
                            onChange={(e) =>
                              setGstPercentage(Number(e.target.value))
                            }
                          />
                        </div>
                        <div className="col-md-3 txtr">{gst.toFixed(2)}</div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-5"></div>
                    <div className="col-md-7">
                      <div className="form-row">
                        <p></p>
                      </div>
                      <div className="form-row">
                        <div className="col-md-5">
                          <input
                            type="text"
                            className={
                              addNewItem
                                ? "form-control heighttext"
                                : "form-control heighttext no-border"
                            }
                            name="Total"
                            style={{ fontWeight: "bold" }}
                            value={totalLabel}
                            onChange={(e) => setTotalLabel(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3"></div>
                        <div
                          className="col-md-3 txtr"
                          style={{ fontWeight: "bold" }}
                        >
                          {total.toFixed(2)}
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                    <div className="form-row" style={{ width: "100%" }}>
                      <br />
                      <br />
                    </div>
                    <div className="form-row" style={{ width: "100%" }}>
                      <div className="col-md-12">
                        <input
                          type="text"
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          name="Notes"
                          onChange={(e) => setNotesLabel(e.target.value)}
                          placeholder="Notes"
                        />
                      </div>
                    </div>
                    <div className="form-row" style={{ width: "100%" }}>
                      <div className="col-md-12">
                        <textarea
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          name="NotesValue"
                          onChange={(e) => setNotesValue(e.target.value)}
                          placeholder="Eg - It was great doing business with you. Edit this field"
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-row" style={{ width: "100%" }}>
                      <br />
                      <br />
                    </div>
                    <div className="form-row" style={{ width: "100%" }}>
                      <div className="col-md-12">
                        <input
                          type="text"
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          name="Terms"
                          onChange={(e) => setTermsLabel(e.target.value)}
                          placeholder="Terms & Conditions"
                        />
                      </div>
                    </div>
                    <div className="form-row" style={{ width: "100%" }}>
                      <div className="col-md-12">
                        <textarea
                          className={
                            addNewItem
                              ? "form-control heighttext"
                              : "form-control heighttext no-borders"
                          }
                          name="TermsValue"
                          onChange={(e) => setTermsValue(e.target.value)}
                          placeholder="Eg - Please make the payment by the due date. Edit this field"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default InvoiceGenerator;
