import React, { FC, useEffect, useState } from "react";
import "./BusinessNameGenerator.css";
import generateNames from "@rstacruz/startup-name-generator";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API_URL } from "../../constants/urls";

interface BusinessNameGeneratorProps {}

const BusinessNameGenerator: FC<BusinessNameGeneratorProps> = () => {
  const [name, setName] = useState<string>();
  const [names, setNames] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 10;

  // Calculate the indices of items to display
  const indexOfLastItem: number = currentPage * itemsPerPage;
  const indexOfFirstItem: number = indexOfLastItem - itemsPerPage;
  const currentItems: string[] =
    names?.slice(indexOfFirstItem, indexOfLastItem) || [];

  // Calculate the total number of pages
  const totalPages: number = Math.ceil(names.length / itemsPerPage);

  // Change page
  const paginate = (pageNumber: number): void => setCurrentPage(pageNumber);

  const generateStartupNames = (name: string) => {
    const result = generateNames(name); // 'cool' is the seed word
    setNames(result);
  };

  useEffect(() => {
    const url = API_URL;
    axios
      .post(
        url + `apis/nuhman/tech/page-view?pageId=` + BusinessNameGenerator.name
      )
      .then((res) => {
        //setCountries(res?.data);
        //setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - Business Name Generator"}</title>
        <meta
          name="description"
          content={
            "Business Name Generator - Generate business names related to the name provided"
          }
        />
        <meta
          property="og:title"
          content={"Nuhman.com - Business Name Generator"}
        />
        <meta
          property="og:description"
          content={
            "Business Name Generator - Generate business names related to the name provided"
          }
        />
      </Helmet>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult col-lg-12">
                  <h3 className=" mg">{"Business Name Generator"}</h3>
                  <p>
                    Please add one keyword, the tool will generate some business
                    names
                  </p>
                  <form
                    name="sentMessage"
                    id="contactForm"
                    onSubmit={(e) => {
                      e.preventDefault();
                      generateStartupNames(name || "");
                    }}
                  >
                    <div className="control-group">
                      <input
                        type="text"
                        id="name"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary font-weight-semi-bold px-4"
                        style={{ height: 50 }}
                        type="submit"
                        id="sendMessageButton"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                  {names && (
                    <div>
                      <h3>Names List</h3>
                      <ul>
                        {currentItems.map((name: string, index: number) => (
                          <li key={index}>{name}</li>
                        ))}
                      </ul>

                      {/* Pagination Controls */}
                      <div>
                        {Array.from({ length: totalPages }, (_, index) => (
                          <button
                            key={index}
                            onClick={() => paginate(index + 1)}
                            style={{
                              margin: "5px",
                              padding: "5px",
                              backgroundColor:
                                currentPage === index + 1
                                  ? "lightblue"
                                  : "white",
                            }}
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessNameGenerator;
