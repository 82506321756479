import React, { FC, useEffect, useState } from "react";
import "./RightSideList.css";
import FollowUs from "../FollowUs/FollowUs";
import NewsLetter from "../NewsLetter/NewsLetter";
import SideAd from "../SideAd/SideAd";
import TagList from "../TagList/TagList";
import Trending from "../Trending/Trending";
import { TechArticles } from "../../interfaces";
import axios from "axios";
import { API_URL } from "../../constants/urls";

interface RightSideListProps {}

const RightSideList: FC<RightSideListProps> = () => {
  const [trendingContents, setTrendingContents] = useState<TechArticles>();
  const url = API_URL;

  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/articles?views=1&page=1&size=4`)
      .then((res) => {
        //setCountries(res?.data);
        setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="col-lg-4 pt-3 pt-lg-0">
      <FollowUs />
      <NewsLetter />
      <SideAd />
      <Trending articles={trendingContents} />
      <TagList />
    </div>
  );
};

export default RightSideList;
