import React, { useState } from "react";
import "./ExpandableMenu.css";

interface ExpandableMenuProps {
  activeTabs: string;
  expandedMenus: string | null;
}

const ExpandableMenu: React.FC<ExpandableMenuProps> = ({
  activeTabs,
  expandedMenus,
}) => {
  // State to track expanded menus
  const [expandedMenu, setExpandedMenu] = useState<string | null>(
    expandedMenus
  );
  // State to track which tab is active
  const [activeTab, setActiveTab] = useState<string | null>(activeTabs); // 'home' is the default active tab

  // Event handler to change active tab
  const handleTabClick = (tab: string) => {
    setActiveTab(tab); // Update state with the selected tab
  };

  // Toggle the expanded state for the selected menu
  const toggleMenu = (menu: string) => {
    setExpandedMenu((prev) => (prev === menu ? null : menu)); // Collapse if the same menu is clicked again
  };

  return (
    <div className="col-lg-3">
      <div className="position-relative bg-light over">
        <div
          className="nav flex-column nav-pills tt"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            className={`nav-link ${activeTab === "home" ? "active" : ""}`}
            href="/#/article-manager"
            aria-selected="false"
          >
            Home
          </a>
          <a
            className={`nav-link ${activeTab === "abarticle" ? "active" : ""}`}
            href="/#/add-business-article"
          >
            Add Business & Finance Articles
          </a>
          <a
            className={`nav-link ${activeTab === "hgarticle" ? "active" : ""}`}
            href="/#/add-hg-article"
          >
            Add Home & Garden Articles
          </a>
          <a className="nav-link" href="/#/logout" aria-selected="false">
            Logout
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExpandableMenu;
