import React, { FC, useEffect, useState } from "react";
import "./Tag.css";
import { TechArticle, TechArticles } from "../../interfaces";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import RightSideList from "../RightSideList/RightSideList";
import { API_URL } from "../../constants/urls";
import ArticleLink from "../ArticleLink/ArticleLink";

interface TagProps {}

const Tag: FC<TagProps> = () => {
  const [popularContents, setPopularContents] = useState<TechArticles>();
  const url = API_URL;
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  useEffect(() => {
    axios
      .get(url + `apis/nuhman/tech/articles/tag?name=` + name)
      .then((res) => {
        //setCountries(res?.data);
        setPopularContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function geTMap(arg0: string | undefined) {
    return arg0?.split(",").map((item) => item.trim());
  }

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">{name} Articles </h3>
                  </div>
                </div>
                <div className="col-lg-12">
                  {popularContents?.techArticles?.map(
                    (article: TechArticle, index) => (
                      <>
                        <div className="col-lg-6 d-flex mb-3 drr" key={index}>
                          <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                            <div className="mb-1 headerthirteen">
                              <a
                                href={
                                  "/#/categories?aqry=true&qqry=true&catid=" +
                                  (article && article?.techCategory?.id)
                                }
                              >
                                {article && article?.techCategory?.name}
                              </a>
                              <span className="px-1">/</span>
                              <span>
                                {article &&
                                  new Date(
                                    article?.createdDateTime || ""
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                              </span>
                            </div>
                            {article && (
                              <ArticleLink
                                className="h6 m-0"
                                title={article?.title || ""}
                                id={article?.id || 0}
                              />
                            )}

                            <p className="tspl">
                              {geTMap(article && article?.tags)
                                ?.slice(0, 5)
                                ?.map((ctry: string, index) => (
                                  <a href={"/#/tags?name=" + ctry} key={index}>
                                    <span className="tsps">{ctry}</span>
                                  </a>
                                ))}
                            </p>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>{" "}
            </div>

            <RightSideList />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tag;
