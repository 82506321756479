import React, { ChangeEvent, FC, useEffect, useState } from "react";
import "./ManagerRightDiv.css";
import { getToken, removeSessionToken } from "../../store/sessionManager";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  admission,
  ArticleCategory,
  college,
  CollegeWebsiteAdderDAO,
  ContentType,
  course,
  educationCategory,
  State,
  techCategory,
} from "../../interfaces";
import { API_URL } from "../../constants/urls";
import { apiPost, apiPostWithFile } from "../../api/apiActions";
import ReactQuill from "react-quill";

interface ManagerRightDivProps {
  aid: number | 5;
}

const ManagerRightDiv: FC<ManagerRightDivProps> = ({ aid }) => {
  const [showAddArticle, setShowAddArticle] = useState<boolean>(false);
  const [showAddWebsite, setShowAddWebsite] = useState<boolean>(false);
  const [collegeId, setCollegeId] = useState("0");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [tags, setTags] = useState("");
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState<number>(0); // Track selected category
  const [pageId, setPageId] = useState("");
  const [states, setStates] = useState<State[]>();
  const [admissions, setAdmissions] = useState<admission[]>();
  const [colleges, setColleges] = useState<college[]>();
  const [collegeWebsite, setCollegeWebsite] =
    useState<CollegeWebsiteAdderDAO>();
  const [courses, setCourses] = useState<course[]>();
  const [contentTypes, setContentTypes] = useState<ContentType[]>();
  const [educationCategories, setEducationCategories] =
    useState<educationCategory[]>();
  const [techCategories, setTechCategories] = useState<techCategory[]>();
  const [techCategy, setTechCategy] = useState<techCategory>();
  const [imageName, setImageName] = useState("");
  const navigate = useNavigate();
  const reader = new FileReader();

  useEffect(() => {
    if (!getToken()) {
      removeSessionToken();
      navigate("/login");
    }
    axios
      .get(`${API_URL}apis/nuhman/tech/categories?show=${aid}`)
      .then((res) => {
        setTechCategories(res?.data);
      })
      .catch((err) => console.log(err));
  }, [aid, navigate]);

  const module = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  async function postArticle(formData: FormData) {
    const response = await apiPost("/apis/nuhman/articles", formData);
    if (response?.status === 401) {
      removeSessionToken();
      navigate("/login");
    } else {
      setTags("");
      setTitle("");
      setValue("");
      // Don't reset the category here
    }
  }

  async function postArticleImage(
    formData: FormData,
    width: number,
    height: number
  ) {
    const response = await apiPostWithFile(
      `/apis/education-data/article/image?width=${width}&height=${height}`,
      formData
    );
    setImageName(response?.data);
  }

  function submitArticle(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.set("description", value);
    formData.set("articleCategoryId", category.toString());

    postArticle(formData);
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
    const filesList = event.target.files;
    if (filesList && filesList.length > 0) {
      reader.readAsDataURL(filesList[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e?.target?.result as string;
        image.onload = () => {
          const { height, width } = image;
          const formData = new FormData();
          formData.append("file", filesList[0]);
          postArticleImage(formData, width, height);
        };
      };
    }
  }

  return (
    <>
      <div className="col-lg-9">
        <div className="position-relative bg-light over">
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
            >
              <>
                <div>
                  <h3>Add Article</h3>
                  <form onSubmit={submitArticle}>
                    <div className="control-group wid">
                      <p>
                        Select Article Category:{" "}
                        <select
                          name="articleCategoryId"
                          className="p-2"
                          value={category} // Set value to selected category
                          onChange={(e) => setCategory(Number(e.target.value))}
                        >
                          <option value={0}>Select</option>
                          {techCategories?.map((ctry: techCategory, index) => (
                            <option key={index} value={ctry.id}>
                              {ctry.name}
                            </option>
                          ))}
                        </select>
                      </p>
                    </div>
                    {techCategy && (
                      <div className="control-group wid">
                        {techCategy?.description}
                      </div>
                    )}

                    <div className="control-group wid">
                      <input
                        type="text"
                        className="form-control p-4"
                        id="title"
                        placeholder="Title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        name="title"
                        required
                      />
                    </div>

                    <div className="control-group wid">
                      <input
                        type="text"
                        className="form-control p-4"
                        id="tags"
                        placeholder="Tags"
                        onChange={(e) => setTags(e.target.value)}
                        value={tags}
                        name="tags"
                      />
                    </div>

                    <div className="control-group wid">
                      <input
                        id="file3"
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                      />
                      {imageName && (
                        <img
                          src={`https://Nuhman.com/images/c${imageName}.JPEG`}
                          width={100}
                          height={100}
                        />
                      )}
                    </div>

                    <div className="control-group wid txarea">
                      <ReactQuill
                        theme="snow"
                        value={value}
                        modules={module}
                        onChange={setValue}
                      />
                    </div>

                    <div className="control-group wid">
                      <input
                        type="text"
                        className="form-control p-4"
                        id="youtubeUrl"
                        placeholder="Youtube Url"
                        onChange={(e) => setYoutubeUrl(e.target.value)}
                        value={youtubeUrl}
                        name="youtubeUrl"
                      />
                    </div>

                    <div>
                      <button
                        className="btn btn-primary font-weight-semi-bold px-4"
                        style={{ height: 50 }}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerRightDiv;
