import React, { FC, useEffect, useState } from "react";
import "./QRCodeGenerator.css";
import { Helmet } from "react-helmet";
import { QRCodeCanvas } from "qrcode.react";
import axios from "axios";
import { API_URL } from "../../constants/urls";

interface QRCodeGeneratorProps {}

const QRCodeGenerator: FC<QRCodeGeneratorProps> = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    const url = API_URL;
    axios
      .post(url + `apis/nuhman/tech/page-view?pageId=` + QRCodeGenerator.name)
      .then((res) => {
        //setCountries(res?.data);
        //setTrendingContents(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {" "}
      <Helmet>
        <title>{"Nuhman.com - QR Code Generator"}</title>
        <meta
          name="description"
          content={"Generate a QR Code for a given input"}
        />
        <meta property="og:title" content={"Nuhman.com - QR Code Generator"} />
        <meta
          property="og:description"
          content={"Generate QR Code for a given input"}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="overlay position-relative bg-light rresult">
              <div className="mb-3 rresult col-lg-12">
                <h3 className=" mg">{"QR Code Generator"}</h3>
                <p>Generate a QR Code for a given input</p>
                <div>
                  <div className="control-group">
                    <input
                      type="text"
                      placeholder="Enter text to generate QR code Eg: https://www.google.com/"
                      className="form-control p-4"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                  </div>
                  <div className="control-group">
                    <br />
                    <QRCodeCanvas value={text} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <p>**</p>
          </div>
        </div>
      </div>{" "}
    </>
  );
};
export default QRCodeGenerator;
