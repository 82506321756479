import React, { FC, useState } from "react";
import "./SurveyBuilderTool.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import { API_URL } from "../../constants/urls";

interface SurveyBuilderToolProps {}

const SurveyBuilderTool: FC<SurveyBuilderToolProps> = () => {
  const [description, setDescription] = useState<string>();
  const [result, setResult] = useState<string>();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const url = API_URL;
  function submitContact(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setLoading(true);
    if (description === null) {
      setError(true);
      return;
    }
    setError(false);
    //let mess = description?.replace(/(?:\r\n|\r|\n)/g, " ");
    //mess = mess?.replace(/["']/g, "");
    //mess = mess?.replace(/“/g, "");
    axios
      .post(url + `apis/nuhman/tech/survey`, {
        description: description,
      })
      .then((res) => {
        //setCountries(res?.data);
        setResult(res?.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Helmet>
        <title>{"Nuhman.com - Survey Builder Tool"}</title>
        <meta
          name="description"
          content={"Survey Builder Tool - Survey Generator"}
        />
        <meta
          property="og:title"
          content={"Nuhman.com - Survey Builder Tool"}
        />
        <meta
          property="og:description"
          content={"Survey Builder Tool - Survey Generator"}
        />
      </Helmet>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult col-lg-12">
                  <h3 className=" mg">{"Survey Builder Tool"}</h3>
                  <p>Please choose a title and description</p>

                  {loading && (
                    <>
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  )}

                  {!loading && (
                    <>
                      {" "}
                      <form
                        name="sentMessage"
                        id="contactForm"
                        onSubmit={submitContact}
                      >
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="title"
                            name="title"
                            placeholder="Title"
                            onChange={(event) => setTitle(event.target.value)}
                            required
                            data-validation-required-message="Please enter a subject"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                          <textarea
                            className="form-control"
                            rows={4}
                            id="text"
                            name="text"
                            placeholder="Description"
                            onChange={(event) =>
                              setDescription(event.target.value)
                            }
                            required
                            data-validation-required-message="Please enter your message"
                          ></textarea>
                          <p className="help-block text-danger"></p>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary font-weight-semi-bold px-4"
                            style={{ height: 50 }}
                            type="submit"
                            id="sendMessageButton"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </>
                  )}

                  <div>
                    {result && (
                      <>
                        {" "}
                        <br />
                        <p>{result}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <p>**</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyBuilderTool;
