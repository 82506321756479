import React, { FC } from "react";
import { slugify } from "../../util/slugify";

interface ArticleLinkProps {
  title: string;
  id: number;
  className?: string; // Optional className prop
}

const ArticleLink: FC<ArticleLinkProps> = ({ title, id, className }) => {
  const slug = slugify(title);
  const articleUrl = `https://nuhmans.com/article/${slug}/${id}`;

  return (
    <a className={className} href={articleUrl}>
      {title}
    </a>
  );
};

export default ArticleLink;
