import React, { FC } from "react";
import "./Trending.css";
import { TechArticle, TechArticles } from "../../interfaces";
import ArticleLink from "../ArticleLink/ArticleLink";

interface TrendingProps {
  articles?: TechArticles;
}

const Trending: FC<TrendingProps> = ({ articles }) => {
  function geTMap(arg0: string | undefined) {
    return arg0?.split(",").map((item) => item.trim());
  }

  function getTrimmed(title: string | ""): React.ReactNode {
    return title?.length > 60 ? title?.substring(0, 60) + " ..." : title;
  }

  return (
    <div className="pb-3">
      <div className="bg-light py-2 px-4 mb-3">
        <h3 className="m-0">Trending</h3>
      </div>
      {(articles?.techArticles?.length || 0 > 0) &&
        articles?.techArticles?.map((articleRes: TechArticle, index) => (
          <>
            <div className="d-flex mb-3 tt" key={index}>
              <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                <div className="mb-1 headerthirteen">
                  <a
                    href={
                      "/#/categories?aqry=true&qqry=true&catid=" +
                      (articleRes && articleRes?.techCategory?.id)
                    }
                  >
                    {articleRes?.techCategory?.name}
                  </a>
                  <span className="px-1">/</span>
                  <span>
                    {new Date(
                      articleRes?.createdDateTime || ""
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </div>

                {articleRes && (
                  <ArticleLink
                    className="h6 m-0"
                    title={
                      articleRes?.title?.length || 0 > 60
                        ? articleRes?.title?.substring(0, 60) + " ..."
                        : articleRes?.title || ""
                    }
                    id={articleRes?.id || 0}
                  />
                )}

                <p className="tspl">
                  {geTMap(articleRes && articleRes?.tags)
                    ?.slice(0, 5)
                    ?.map((ctry: string, index) => (
                      <a href={"/#/tags?name=" + ctry} key={index}>
                        <span className="tsps">{ctry}</span>
                      </a>
                    ))}
                </p>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default Trending;
