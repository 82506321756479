import React, { FC, useEffect, useState } from "react";
import "./TechCategory.css";
import axios from "axios";
import {
  ArticleContentType,
  TechArticle,
  TechArticles,
  techCategory,
} from "../../interfaces";
import FollowUs from "../FollowUs/FollowUs";
import NewsLetter from "../NewsLetter/NewsLetter";
import { useLocation, useSearchParams } from "react-router-dom";
import SideAd from "../SideAd/SideAd";
import Trending from "../Trending/Trending";
import TagList from "../TagList/TagList";
import RightSideList from "../RightSideList/RightSideList";
import { API_URL } from "../../constants/urls";
import ArticleLink from "../ArticleLink/ArticleLink";

interface TechCategoryProps {
  catId: number;
  qas: boolean;
  ars: boolean;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TechCategory: FC<TechCategoryProps> = ({ catId, qas, ars }) => {
  const [popularContents, setPopularContents] = useState<TechArticles>();
  const url = API_URL;
  const [articleCategory, setArticleCategory] = useState<techCategory>();
  const [qa, setQa] = useState<boolean>(false);
  const [ar, setAr] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [size, setSize] = useState<number>(10); // Default page size
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const location = useLocation();
  const query = useQuery();
  // Fetch articles based on the current page
  const fetchArticles = (page: number) => {
    setLoading(true); // Start loading
    axios
      .get(
        `${url}/apis/nuhman/tech/articles?categoryId=${catId}&page=${page}&size=${size}`
      )
      .then((res) => {
        setPopularContents(res.data);
        setCurrentPage(res.data.currentPage);
        setTotalPages(res.data.totalPages);
        setLoading(false); // Stop loading
      })
      .catch((err) => {
        console.log(err);
        setLoading(false); // Stop loading in case of error
      });
  };

  useEffect(() => {
    // This will run whenever the query parameters change
    const catid = query.get("catid");
    const aqry = query.get("aqry");
    axios
      .get(url + `apis/nuhman/tech/category/` + catId)
      .then((res) => {
        setArticleCategory(res?.data);
      })
      .catch((err) => console.log(err));

    fetchArticles(0);
  }, [location.search]);

  function geTMap(arg0: string | undefined) {
    return arg0?.split(",").map((item) => item.trim());
  }

  // Function to handle page changes
  const handlePageChange = (newPage: number) => {
    fetchArticles(newPage);
  };

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {ars && (
                <>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                        <h3 className="m-0">
                          {articleCategory?.name} Articles{" "}
                        </h3>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      {/* Show loading spinner while fetching data */}
                      {loading && (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      )}

                      {!loading &&
                        popularContents?.techArticles?.map(
                          (article: TechArticle, index) => (
                            <>
                              <div
                                className="col-lg-6 d-flex mb-3 drr"
                                key={index}
                              >
                                <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                                  <div className="mb-1 headerthirteen">
                                    <a
                                      href={
                                        "/#/categories?aqry=true&qqry=true&catid=" +
                                        (article && article?.techCategory?.id)
                                      }
                                    >
                                      {article && article?.techCategory?.name}
                                    </a>
                                    <span className="px-1">/</span>
                                    <span>
                                      {article &&
                                        new Date(
                                          article?.createdDateTime || ""
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                        })}
                                    </span>
                                  </div>
                                  {article && (
                                    <ArticleLink
                                      className="h6 m-0"
                                      title={article?.title || ""}
                                      id={article?.id || 0}
                                    />
                                  )}

                                  <p className="tspl">
                                    {geTMap(article && article?.tags)
                                      ?.slice(0, 5)
                                      ?.map((ctry: string, index) => (
                                        <a
                                          href={"/#/tags?name=" + ctry}
                                          key={index}
                                        >
                                          <span className="tsps">{ctry}</span>
                                        </a>
                                      ))}
                                  </p>
                                </div>
                              </div>
                            </>
                          )
                        )}
                    </div>
                  </div>

                  <div className="pagination-controls d-flex justify-content-center align-items-center mt-3">
                    <button
                      className="btn btn-outline-primary me-2"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 0 || loading}
                    >
                      Previous
                    </button>
                    <span className="mx-3">{`Page ${
                      currentPage + 1
                    } of ${totalPages}`}</span>
                    <button
                      className="btn btn-outline-primary ms-2"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage + 1 >= totalPages || loading}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {qas && (
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      <h3 className="m-0">Q & A </h3>
                    </div>
                    <p>No QA</p>
                  </div>
                </div>
              )}
            </div>

            <RightSideList />
          </div>
        </div>
      </div>
    </>
  );
};

export default TechCategory;
