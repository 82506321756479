import React, { FC, useState } from "react";
import "./AddHomeAndGardenArticle.css";
import ExpandableMenu from "../ExpandableMenu/ExpandableMenu";
import ManagerRightDiv from "../ManagerRightDiv/ManagerRightDiv";

interface AddHomeAndGardenArticleProps {}

const AddHomeAndGardenArticle: FC<AddHomeAndGardenArticleProps> = () => {
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("hgarticle");
  return (
    <>
      <div className="container">
        <div className="row">
          <ExpandableMenu activeTabs={activeTab} expandedMenus={expandedMenu} />
          <ManagerRightDiv aid={2} />
        </div>
      </div>
    </>
  );
};

export default AddHomeAndGardenArticle;
